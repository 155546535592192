import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Footer.css';
import logo from '../Images/transparent.png';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaWhatsapp, FaPhone, FaEnvelope, FaMapMarkerAlt, FaBook, FaUserGraduate, FaInfoCircle, FaBlogger, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  const handleWhatsAppButtonClick = () => {
    window.open('https://chat.whatsapp.com/EtIysxsIEd7KK0lGi7ruGy', '_blank');
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <motion.div 
          className="footer-logo-section"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <img src={logo} alt="Rahar Stock Market Academii Logo" className="footer-logo" />
          <p className="footer-tagline">LEARN TO EARN: TRANSFORMING KNOWLEDGE INTO PROSPERITY</p>
        </motion.div>

        <motion.div 
          className="footer-links"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h3>Quick Links</h3>
          <ul>
            <li><FaBook /> <Link to="/courses">Our Courses</Link></li>
            <li><FaUserGraduate /> <Link to="/open-demat">Open Demat Account</Link></li>
            <li><FaInfoCircle /> <Link to="/about">About Us</Link></li>
            <li><FaBlogger /> <Link to="/blog">Blog</Link></li>
            <li><FaEnvelope /> <Link to="/contact">Contact</Link></li>
          </ul>
        </motion.div>

        <motion.div 
          className="footer-contact"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
         
         
        >
          <h3>Get in Touch</h3>
          <p><FaMapMarkerAlt /> Sector 65, Mohali, Punjab, India</p>
          <p><FaPhone /> <a href="tel:+917508622302">+91 75086-22302</a></p>

          <p><FaEnvelope /> <a href="mailto:ShhivamRahar@gmail.com">ShiivamRahhar@gmail.com</a></p>

  
  

        </motion.div>

        <motion.div 
          className="footer-social"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h3>Follow Us</h3>
         <div className="social-icons">
            <a href="https://www.youtube.com/channel/UC21zJ_6azEJARDWbnAOBo0" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://www.instagram.com/raharstockmarketacademy/?hl=ens" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram /></a>
            <a href="https://www.youtube.com/channel/UC21zJ_6azEJARDWbnAOBo0" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><FaYoutube /></a>
           
            
            <a href="https://www.instagram.com/raharstockmarketAcademy/?hl=ens" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram /></a>
            <a href="https://www.youtube.com/channel/UC21zJ_6azEJARDWbnAOBo0" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><FaYoutube /></a>
           
            
          </div>
          <motion.button 
            className="whatsapp-btn"
            onClick={handleWhatsAppButtonClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaWhatsapp /> Join Our Community
          </motion.button>
        </motion.div>
      </div>

      <motion.div 
        className="footer-bottom"
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
      >
        <p>&copy; {new Date().getFullYear()} Rahar Stock Market Academii. All rights reserved.</p>
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link> | 
          <Link to="/terms-of-service">Terms of Service</Link>
        </p>
      </motion.div>
    </footer>
  );
};

export default Footer;
